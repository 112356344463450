import { useState, useEffect, lazy, Suspense } from "react"
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom';
import { lang, img_url } from '../components/lang'
import { useLocation } from "react-router-dom";
import api from '../components/api'
import styles from '../assets/css/ebook.module.css';

const Menu = lazy(() => import ('../components/Template/Menu'))
const Nav = lazy(() => import ('../components/Template/Nav'))
const BookShelf = lazy(() => import ('../components/EBook/BookShelf'))
const BookDetail = lazy(() => import ('../components/EBook/BookDetail'))

export default function Document(props) {
    const [init, setInit] = useState([]);
	  const navigate = useNavigate();
    const [category_id, setCategoryId] = useState(0);
    const [dynasty_id, setDynastyId] = useState(0);
    const [nav_data, setNavData] = useState([]);
    const [document, setDocument] = useState({});
    const [documents, setDocuments] = useState([]);
    const [dynasties, setDynasties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filterDocuments, setFilterDocuments] = useState([]);
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    let keyword = (params.get('keyword'))?params.get('keyword'):'';
    let documentid = (params.get('id'))?params.get('id'):0;
    const [document_id, setDocumentID] = useState(documentid);
    const [filter, setFilter] = useState(keyword);
    const type_params = useParams();

    useEffect(()=>{
        let type = 'all';
        if(props.categoryId===''){
          if(type_params.categoryId){
              type = type_params.categoryId;
              setCategoryId(type_params.categoryId);
          }else{
            setCategoryId(0);
          }
        }else{
          type = props.categoryId;
          if(type!=="all"){
            setCategoryId(props.categoryId);
          }
        }
        const fetchInit = async () =>{
          try {
            const response = await api.get('/menu/3');
            setNavData(response.data);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
          try {
            const response = await api.get('/setting');
            setInit(response.data.results);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
          try {
            const response = await api.get('/document/type/'+type+"?dynasty_id="+dynasty_id);
            setDocuments(current => response.data.results);
            console.log(response.data.results);
            if(keyword!==''){
              let filteredItems = response.data.results.filter(
                    item => item.name.toLowerCase().includes(filter.toLowerCase()) || 
                    item.name_cn.toLowerCase().includes(filter.toLowerCase()) || 
                    item.altar.toLowerCase().includes(filter.toLowerCase()) || 
                    item.altar_cn.toLowerCase().includes(filter.toLowerCase()) || 
                    item.detail.toLowerCase().includes(filter.toLowerCase()) || 
                    item.all_vocab.toLowerCase().includes(filter.toLowerCase()) || 
                    item.detail_cn.toLowerCase().includes(filter.toLowerCase()),
                );
              setFilterDocuments(filteredItems);
            }else{
              setFilterDocuments(response.data.results);
            }
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
        }
        fetchInit();

    },[]);

    useEffect(()=>{
        setDocumentID(0);
        setDocument({});
        const fetchInit = async () =>{
            let menu_id = 2;
            try {
              const tresp = await api.get('/category');
              setCategories(tresp.data.results);
              const cresp = await api.get('/dynasty');
              setDynasties(cresp.data.results);
              // console.log(cresp.data.results);
              const response = await api.get('/menu/'+menu_id);
              setNavData(current => response.data);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
            try {
              let c_id = category_id;
              if(parseInt(category_id)===0){
                  c_id = 'all';
              }
              const response = await api.get('/document/type/'+c_id+"?dynasty_id="+dynasty_id);
              setDocuments(current => response.data.results);
              if(keyword!==''){
                let filteredItems = response.data.results.filter(
                      item => item.name.toLowerCase().includes(filter.toLowerCase()) || 
                      item.name_cn.toLowerCase().includes(filter.toLowerCase()) || 
                      item.altar.toLowerCase().includes(filter.toLowerCase()) || 
                      item.altar_cn.toLowerCase().includes(filter.toLowerCase()) || 
                      item.detail.toLowerCase().includes(filter.toLowerCase()) || 
                      item.all_vocab.toLowerCase().includes(filter.toLowerCase()) || 
                      item.detail_cn.toLowerCase().includes(filter.toLowerCase()),
                  );
                setFilterDocuments(filteredItems);
              }else{
                setFilterDocuments(response.data.results);
              }
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[category_id, dynasty_id]);

    useEffect(()=>{
      setDocumentID(0);
      setDocument({});
      if(filter!==""){
          setCategoryId(0);
          setDynastyId(0);
      } 
      let filteredItems = documents.filter(
            item => item.name.toLowerCase().includes(filter.toLowerCase()) || 
            item.name_cn.toLowerCase().includes(filter.toLowerCase()) || 
            item.altar.toLowerCase().includes(filter.toLowerCase()) || 
            item.altar_cn.toLowerCase().includes(filter.toLowerCase()) || 
            item.detail.toLowerCase().includes(filter.toLowerCase()) || 
            item.all_vocab.toLowerCase().includes(filter.toLowerCase()) || 
            item.detail_cn.toLowerCase().includes(filter.toLowerCase()),
        );
      setFilterDocuments(filteredItems);
    },[filter]);

    const goBack = (e) =>{
        e.preventDefault();
        setCategoryId(0);
        setDynastyId(0);
    }

    return (
        <div className={"container "+styles["ebook-container"]}>
              <Nav styles={styles} nav_data={nav_data}  category_id={category_id} dynasty_id={dynasty_id} categories={categories} dynasties={dynasties} setDynastyId={setDynastyId} setCategoryId={setCategoryId} />
              <div className={styles["content"]}>
                  <Menu category_id={category_id} setCategoryId={setCategoryId} dynasty_id={dynasty_id} setDynastyId={setDynastyId} dynasties={dynasties} categories={categories} setFilter={setFilter} filter={filter} right={true}/>
                  <div className={styles["content-container"]}>
                  {(document_id===0)?(
                    (parseInt(dynasty_id)===0&&filter==='')?(
                      <div className={styles["book-category"]}>
                          <h3>{lang('หมวดหมู่','Categories')}</h3>
                          <ul>
                              {
                                  categories.map((item, index) => (
                                      <li key={'ebook-'+index} onClick={e=>setCategoryId(item.id)} className={(category_id===item.id)?styles["active"]:''}>
                                          <h4 className={(parseInt(item.id)===parseInt(category_id))?styles["active"]:''}>{item.name}</h4>
                                          {
                                          (parseInt(item.id)===parseInt(category_id))?
                                            <ul>
                                           { dynasties.map((ditem, dindex) => (
                                                <li key={'d-'+index+'-'+dindex} onClick={e=>setDynastyId(ditem.id)} className={(dynasty_id===ditem.id)?styles["active"]:''}>
                                                    {ditem.name} (<span className="pinyin">{ditem.name_cn}</span>)
                                                </li>
                                          ))}
                                          </ul>
                                          :''
                                        }
                                      </li>
                                  ))
                              }
                              {/* <li onClick={e=>handleClick("all")} className={(category_id==="all")?styles["active"]:''}>{lang('ทรัพยากรสารสนเทศทั้งหมด','All Resources')}</li> */}
                          </ul>
                      </div>
                    ):(
                    <Suspense fallback={<h1>Loading Shelf</h1>}>
                        <BookShelf goBack={goBack} styles={styles} data={filterDocuments} category_id={category_id} setDocumentID={setDocumentID} dynasties={dynasties} categories={categories} setCategoryId={setCategoryId} setDocument={setDocument}/>
                    </Suspense>
                    )
                  ):(
                    <Suspense fallback={<h1>Loading Document</h1>}>
                        <BookDetail styles={styles} data={document} setDocumentID={setDocumentID} setDocument={setDocument}/>
                    </Suspense>
                  )}
                  </div>
              </div>
        </div>
    )
}